import EmptyUtil from "@/utils/common/EmptyUtil";
import {isReactive, toRaw} from "vue";
import deepClone from "@/utils/deepClone";

export default {
    assign(target, source) {
        if (!target) {
            return source;
        }
        if (!source) {
            return target;
        }
        for (let prop in source) {
            target[prop] = source[prop];
        }
    },
    // 将数据深拷贝一份成接口参数，字段为空则删除
    toParams(target) {
        if (EmptyUtil.isEmpty(target)) {
            return {};
        }
        let res = {};
        if (isReactive(target)) {
            res = deepClone(toRaw(target));
        } else {
            res = deepClone(target);
        }

        for (let prop in res) {
            if (EmptyUtil.isEmpty(res[prop])) {
                delete res[prop];
            }
        }
        return res;
    },
}