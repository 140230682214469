<template>
  <!-- 添加质检类型 -->
  <a-modal width="690px" :visible="dialogVisible" title="添加质检规则" @cancel="dialogClose">
    <div class="add-from-s">
      <a-form layout="horizontal" :model="editForm" :rules="editFormRules" ref="editFormRef">
        <a-form-item label="质检类型" name="name">
          <a-input v-model:value="editForm.name" placeholder="请输入质检类型"></a-input>
        </a-form-item>
        <a-form-item label="风险等级" name="risk">
          <a-radio-group v-model:value="editForm.risk" button-style="solid">
            <a-radio-button :value="3">高</a-radio-button>
            <a-radio-button :value="2">中</a-radio-button>
            <a-radio-button :value="1">低</a-radio-button>
            <a-radio-button :value="0">无</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="状态" name="status">
          <a-radio-group v-model:value="editForm.status">
            <a-radio :value="0">正常</a-radio>
            <a-radio :value="1">禁用</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="微信通知" name="noticeWx">
          <a-radio-group v-model:value="editForm.noticeWx">
            <a-radio :value="1">开启</a-radio>
            <a-radio :value="0">关闭</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="敏感词" name="keyword">
          <a-input v-model:value="keyword" placeholder="请输入敏感词后回车"
                   @pressEnter="addKeyword"/>
        </a-form-item>
        <a-form-item label="关键词设置规则：">
          <div>
            <p>1、单个关键词输入后，按回车键保存（装修）+ 回车键 </p>
            <p>2、多个关键词请使用英文状态下“,”分割（贷款,利率,装修）+ 回车键</p>
            <p>3、组合关键词请使用“&”分割（贷款&利率&装修,教育&培训&幼儿）</p>
          </div>
          <div style="height: 200px;overflow: auto;border: 1px solid #e4e4e4;padding: 10px">
            <a-tag style="margin-bottom: 10px" @close="deleteKeyword(item)" closable
                   v-for="(item, index) in keywords" :key="index">{{ item }}
            </a-tag>
            <a-tag v-if="keywords&&keywords.length>0"
                   style="margin-bottom: 10px;margin-right: 10px;"
                   color="processing"
                   @click="copyKeywords"
                   key="copy">复制
            </a-tag>
          </div>
        </a-form-item>
      </a-form>
    </div>
    <template #footer>
      <a-button key="back" @click="dialogClose">取消</a-button>
      <a-button key="submit" type="primary" :loading="dialogLoading" @click="dialogConfirm">保存</a-button>
    </template>
  </a-modal>
</template>

<script>
import AModal from "ant-design-vue/lib/modal/Modal";
import {reactive, ref, toRefs, watch} from "vue";
import {safePromise} from "@/utils/common/safePromise";
import _lineService from "@/api/open/LineQualityInspections";
import EmptyUtil from "@/utils/common/EmptyUtil";
import {copy} from "@/utils/common";
import {useStore} from "vuex";
import ObjectUtil from "@/utils/common/ObjectUtil";
import {message} from "ant-design-vue";

export default {
  name: "EditKeywordDialog",
  emits: ['update:dialogVisible', 'confirm'],
  components: {AModal},
  props: {
    dialogVisible: {
      type: Boolean,
      default: () => false,
    },
    record: {
      type: Object,
      default: {},
    },
  },
  setup(props, context) {

    let {record} = toRefs(props);
    let store = useStore();
    let dialogLoading = ref(false);

    let keyword = ref(null);
    let keywords = ref([]);
    let editForm = reactive({
      name: null,
      risk: 3,//默认高
      status: 0,
      noticeWx: 1,
      keywords: '',
    });
    let resetForm = () => {
      keyword.value = null;
      keywords.value = [];
      editForm.id = null;
      editForm.uid = null;
      editForm.name = null;
      editForm.risk = 3;
      editForm.status = 0;
      editForm.noticeWx = 1;
      editForm.keywords = '';
    }

    let editFormRef = ref();

    watch(record, (newV, preV) => {
      // console.log('watch record', newV)
      if (!EmptyUtil.isEmpty(newV)) {
        Object.assign(editForm, newV);
        keywords.value = (Array.isArray(newV.keywords) ? newV.keywords : newV.keywords?.split(',')) || []
      } else {
        resetForm();
      }
    })

    let editFormRules = {
      name: [{required: true, message: '请输入质检类型', trigger: 'blur'}],
      risk: [
        {required: true, type: 'number', message: '请选择风险等级11111', trigger: 'change'},
      ],
      status: [
        {
          required: true,
          type: 'number',
          message: '请选择状态',
          trigger: 'change',
        },
      ],
      noticeWx: [
        {
          required: true,
          type: 'number',
          message: '请选择微信通知开启状态',
          trigger: 'change',
        },
      ],
    };

    let addKeyword = () => {
      if (EmptyUtil.isEmpty(keyword.value)) {
        return;
      }
      if (!keywords.value) {
        keywords.value = [];
      }
      if (keyword.value.indexOf(',') !== -1) {
        let keywordsSplit = keyword.value
            .split(',')
            .map(item => item.trim())
            .filter(item => item && item.length > 0)
            .filter(item => !keywords.value.find(keyword => keyword == item));
        keywords.value = keywords.value.concat(keywordsSplit);
      } else {
        if (!!keyword.value.trim() && !keywords.value.find(item => item == keyword.value.trim())) {
          keywords.value.push(keyword.value.trim())
        }
      }
      keyword.value = null;
    }
    let deleteKeyword = (keyword) => {
      let findIndex = keywords.value.findIndex(item => item == keyword);
      if (findIndex != -1) {
        keywords.value.splice(findIndex, 1)
      }
    }
    let copyKeywords = () => {
      if (!EmptyUtil.isEmpty(keywords.value)) {
        let copyText = keywords.value.join(',');
        copy(copyText);
      }
    }
    let dialogClose = () => {
      context.emit('update:dialogVisible', false);
    }
    let dialogConfirm = async () => {
      let valid = await editFormRef.value?.validate();
      // console.log('dialogConfirm valid', valid, editFormRef.value);
      if (valid) {
        dialogLoading.value = true;
        if (EmptyUtil.isEmpty(editForm.uid)) {
          editForm.uid = store.state.user.uid;
        }
        let res;
        let params = ObjectUtil.toParams(editForm);
        params.keywords = keywords.value?.join(',') || '';
        if (editForm.id) {
          res = await safePromise(_lineService._apiService.updateKeyword(params));
        } else {
          res = await safePromise(_lineService._apiService.addKeyword(params));
        }
        dialogLoading.value = false;
        if (res?.code == 200) {
          if (!EmptyUtil.isEmpty(res?.data)) {
            Object.assign(params, res.data);
          }
          message.success('编辑成功')
          context.emit('update:dialogVisible', false);
          context.emit('confirm', params);
        }
        dialogLoading.value = false;
      }

    }

    return {
      dialogLoading, editForm, keyword, keywords, editFormRules, editFormRef,
      addKeyword, deleteKeyword, copyKeywords,
      dialogClose, dialogConfirm,
    }
  },
}
</script>

<style scoped>

</style>